<script setup>
    import PrimevuePortal from 'primevue/portal';

    const { $me } = useNuxtApp();

    /**
     * Application header for the frontend layout
     */
    const viewport = useViewport();

    // Datas initialization
    const scrollPosition = ref(0);
    const scrollDirection = ref('down');
    const headerFixed = ref(false);
    const transitionEnabled = ref(false);

    // Props definition
    const props = defineProps({
        // Main menu items
        menus: {
            type: Array,
            required: true,
        },
        // CTA menu items (Login, Register, ...)
        cta: {
            type: Array,
            required: true,
        },
        // Paths to switch locale
        paths: {
            type: Object,
        },
    });

    /**
     * Get the scroll direction and scroll position
     * to show or hide the navbar
     */
    const handleScroll = () => {
        let currentPos = window.scrollY;

        // If current scroll position lower than previous scroll position,
        // the user is scrolling up, else he's going down
        scrollDirection.value = currentPos < scrollPosition.value ? 'up' : 'down';

        // If the user has scrolled more than 250px, then
        // the header become fixed until the user scrolls up to 0
        if (currentPos > 250) {
            headerFixed.value = true;

            setTimeout(() => {
                // Enable transition after a tick to avoid visual glitch
                // between position fixed and absolute
                transitionEnabled.value = true;
            }, 0);
        }

        // If the user has scrolled up to 0, then
        // the header become absolute
        if (currentPos === 0) {
            headerFixed.value = false;
            transitionEnabled.value = false;
        }

        // Save current position as previous position
        scrollPosition.value = currentPos;
    };

    // On mounted, add scroll event listener
    onMounted(() => {
        window.addEventListener("scroll", handleScroll);
    });

    // On unmounted, remove scroll event listener
    onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll);
    });
</script>

<template>
    <header id="app__header" :class="{transition: transitionEnabled, fixed: headerFixed, show: scrollDirection === 'up'}">
        <!-- Main header -->
        <nav id="app__header__main">
            <!-- Menubar -->
            <PrimevueMenubar :model="props.menus">
                <!-- Logo at the start of the bar -->
                <template #start>
                    <div id="app__header__main__logo">
                        <LayoutLogo />
                    </div>
                </template>

                <!-- End of the bar -->
                <template #end>
                    <div id="app__header__main__end">

                        <!-- Lang switcher slot -->
                        <slot name="langswitcher">
                            <LangSwitcher :paths="props.paths"/>
                        </slot>

                        <div v-if="$me.check() && $me.get().novu_id">
                            <LazyNovuNotificationCenter />
                        </div>

                        <LoginButtons v-if="viewport.isGreaterOrEquals('lg')" id="app__header__main__buttons" />
                    </div>
                </template>
            </PrimevueMenubar>

            <!-- Boutons de connexion -->
            <PrimevuePortal
                v-if="! viewport.isGreaterOrEquals('lg')"
                appendTo="#app__header__main .p-menubar-root-list"
            >
                <LoginButtons id="app__header__main__buttons" />
            </PrimevuePortal>
        </nav>

        <!-- Subheader slot -->
        <section id="app__header__sub">
            <slot name="subheader" />
        </section>
    </header>
</template>
