<script setup>
    /**
     * Application footer for the frontend layout
     */

    // Props definition
    const props = defineProps({
        // App menus
        menus: {
            type: Object,
            required: true,
        },
        // Reviews list
        reviews: {
            type: Array,
            required: true,
        },
        // Stocklear contact details
        stocklear: {
            type: Object,
            required: true,
        },
    });

    /**
     * If we want to go to current URL, just scroll to top
     * else, go to the given URL
     * @param {String} url 
     */
    const goTo = (url) => {
        if (url === useRoute().path) {
            window.scrollTo(0, 0);
        } else {
            navigateTo(url, {external: true});
        }
    }
</script>

<template>
    <footer id="app__footer">
        <!-- Reviews -->
        <LayoutFrontendReviews :reviews="reviews" />

        <!-- Stocklear qualities -->
        <section id="app__footer__qualities">
            <div class="container">
                <!-- Customer service -->
                <div>
                    <h3><Icon name="ic:baseline-support-agent" />{{ $t('Customer service') }}</h3>
                    <p>{{ $t('Our customer service team based in France speaks French, English and Spanish and is here to answer all your questions. You can reach us by chat or through your account.') }}</p>
                </div>

                <!-- Secure payment -->
                <div>
                    <h3><Icon name="ic:baseline-credit-score" />{{ $t('Secure payment') }}</h3>
                    <p>{{ $t('Feel confident buying through our marketplace. Sellers are paid once you have validated the receipt of your orders.') }}</p>
                </div>

                <!-- Quality -->
                <div>
                    <h3><Icon name="ic:baseline-thumb-up" />{{ $t('Quality') }}</h3>
                    <p>{{ $t('The reseller lots, the clearance lots you have access to come directly from the major brands and retailers.') }}</p>
                </div>
            </div>
        </section>

        <!-- Footer navigation -->
        <section id="app__footer__nav">
            <nav>
                <!-- Head office -->
                <div>
                    <h3>{{ $t('Head office') }}</h3>
                    <ul class="icon_menu">
                        <li><Icon name="ic:baseline-location-on" /><a href="https://goo.gl/maps/DH7GC1XfJ9n8Caob6">{{ stocklear.address }}</a></li>
                    </ul>
                </div>

                <!-- About -->
                <div>
                    <h3>{{ $t('About') }}</h3>
                    <ul>
                        <li v-for="menu in menus['FOOTER_MENU']">
                            <a :href="menu.path" :title="menu.label" @click.prevent="goTo(menu.path)">{{ menu.label }}</a>
                        </li>
                    </ul>
                </div>

                <!-- Buyers -->
                <div>
                    <h3>{{ $t('Buyers') }}</h3>
                    <ul>
                        <li v-for="menu in menus['FOOTER_MENU_SELLER']">
                            <a :href="menu.path" :title="menu.label" @click.prevent="goTo(menu.path)">{{ menu.label }}</a>
                        </li>
                    </ul>
                </div>

                <!-- Sellers -->
                <div>
                    <h3>{{ $t('Sellers') }}</h3>
                    <ul>
                        <li v-for="menu in menus['FOOTER_MENU_BUYEUR']">
                            <a :href="menu.path" :title="menu.label" @click.prevent="goTo(menu.path)">{{ menu.label }}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </section>

        <!-- Footer bottom (copyrights & socials) -->
        <section id="app__footer__bottom">
            <!-- Copyrights -->
            <div id="app__footer__bottom__copyrights">
                <div>© Stocklear 2018-{{ new Date().getFullYear() }}</div>
                <div>{{ $t('All rights reserved') }}</div>

                <div v-for="menu in menus['FOOTER_COPYRIGHT']">
                    <a :href="menu.path">{{ menu.label }}</a>
                </div>
            </div>

            <!-- Socials -->
            <div id="app__footer__bottom__socials">
                <a target="_blank" href="https://www.linkedin.com/company/stocklear/">
                    <Icon name="akar-icons:linkedin-box-fill" />
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCgoSNmPTIKdpeqikoVV4DVQ">
                    <Icon name="akar-icons:youtube-fill" />
                </a>
                <a target="_blank" href="https://www.facebook.com/Stocklear">
                    <Icon name="ic:baseline-facebook" />
                </a>
            </div>
        </section>
    </footer>
</template>
