<script setup>
    import PrimevueToast from 'primevue/toast';
    import { dragscroll } from 'vue-dragscroll';

    const { $api, $me } = useNuxtApp();

    const viewport = useViewport();

    // Use dragscroll directive
    const vDragscroll = dragscroll;

    // Initialize i18n
    const { localeProperties, locales } = useI18n();
    const locale = computed(() => localeProperties.value.language);
    const switchLocalePath = useSwitchLocalePath();
    const localePath = useLocalePath();

    const props = defineProps({
        fullScreen: {
            type: Boolean,
            default: false,
        },
        enableHeadAlternate: {
            type: Boolean,
            default: true,
        },
        showSubHeader: {
            type: Boolean,
            default: true,
        },
    });

    // Load frontend layout resources
    const { data: layout } = await $api.get('v1/layout/frontend', {
        params: { locale: locale.value },
    });
    const menus = ref(layout.value.menus);
    const reviews = ref(layout.value.reviews);
    const stocklear = ref(layout.value.stocklear);
    const categories = ref(layout.value.categories);
    const banner = ref(layout.value.banner);

    const requestUrl = useRequestURL();
    const currentPath = useRoute().path.split('#')[0].split('?')[0];

    // Add data layout attribute to body
    useHead({
        link: [
            {
                rel: 'canonical',
                href: requestUrl.href,
            },
        ],
        bodyAttrs: {
            'data-layout': 'frontend',
        },
    });

    if (props.enableHeadAlternate) {
        for (let locale of locales.value) {
            let iso = locale.language;

            useHead({
                link: [
                    {
                        rel: 'alternate',
                        href: switchLocalePath(locale.code),
                        hreflang: iso,
                    },
                ],
            });
        }
    }

    /**
     * Get current route translated for each locales
     * @returns {Object}
     */
    const paths = computed(() => {
        let paths = {};
        let config = useRuntimeConfig();

        for (let domain of config.public.domains) {
            paths[domain.code] = switchLocalePath(domain.code).replace(':://', '://');
        }

        return paths;
    });

    /**
     * Get the correct url to complete the account
     * @returns {String}
     */
    const completionUrl = computed(() => {
        if ($me.isBuyer()) {
            return localePath('register-activity');
        } else {
            return localePath('register-seller-activity');
        }
    });

    /**
     * Format the menus to be used by the menubar component
     * @returns {Array}
     */
    const menusFormatted = computed(() => {
        if (menus.value['PRIMARY_MENU'])
            return menus.value['PRIMARY_MENU'].map((menu) => {
                let hasChildren = menu.children && menu.children.length;

                let col = { label: menu.label };

                if (hasChildren) {
                    col.items = menu.children.map((child) => {
                        return {
                            label: child.label,
                            url: child.path,
                        };
                    });
                } else {
                    col.url = menu.path;
                }

                return col;
            });
    });

    // Envoi d'un event au dataLayer lorsqu'un acheteur n'a pas d'adresse de livraison.
    onMounted(async () => {
        if ($me.isBuyer() && $me.isValidated()) {
            const { data: addresses } = await $api.get('v1/buyer/warehouses');

            if (addresses.value?.data.length === 0) {
                dataLayer.push({
                    event: 'user_engagement',
                    event_name: 'show_add_address_banner',
                    user_role: 'buyer',
                });
            }
        }
    });

    const gtmMarketplaceButton = () => {
        // GTM Click - CTA - Marketplace
        dataLayer.push({
            event: 'generic_event',
            event_name: 'click_marketplace',
            button_id: 'header',
            button_name: 'header',
        });
    };

    const gtmPromotion = (slot, banner) => {
        // GTM Click - Promotion
        dataLayer.push({
            event: 'ecommerce',
            event_name: 'select_promotion',
            ecommerce: {
                creative_name: banner.img,
                creative_slot: slot,
                promotion_id: banner.url,
                promotion_name: banner.alt,
            },
        });
    };
</script>

<template>
    <NuxtErrorBoundary>
        <!-- Header -->
        <LayoutFrontendHeader :menus="menusFormatted" :cta="menus['CTA_MENU']">
            <!-- Main nav -->
            <template #langswitcher>
                <slot name="langswitcher">
                    <LangSwitcher :paths="paths" />
                </slot>
            </template>

            <!-- Sub nav -->
            <template #subheader v-if="props.showSubHeader">
                <div class="container">
                    <div id="subheader__categories" v-dragscroll="!viewport.isGreaterThan('md')">
                        <div :class="{ active: currentPath === localePath('index') }">
                            <NuxtLink :to="localePath('index')" @click="gtmMarketplaceButton">
                                <template v-if="viewport.isGreaterThan('md')">
                                    {{ $t('All lots') }}
                                </template>

                                <PrimevueTag
                                    v-else
                                    class="p-tag-secondary"
                                    :value="$t('All lots')"
                                    rounded
                                />
                            </NuxtLink>
                        </div>

                        <div v-if="!viewport.isGreaterThan('md') && banner?.alt">
                            <NuxtLink :to="banner.url">
                                <PrimevueTag
                                    :value="banner.alt"
                                    rounded
                                    :style="`background-color: ${banner.background_color}`"
                                />
                            </NuxtLink>
                        </div>

                        <div
                            v-for="category in categories"
                            :class="{ active: currentPath === category.url }"
                        >
                            <NuxtLink :to="localePath(category.url)">
                                <template v-if="viewport.isGreaterThan('md')">
                                    {{ category.name }}
                                </template>

                                <PrimevueTag
                                    v-else
                                    class="p-tag-secondary"
                                    :value="category.name"
                                    rounded
                                />
                            </NuxtLink>
                        </div>
                    </div>

                    <div v-if="banner" id="subheader__banner" class="hidden-lg">
                        <NuxtLink :to="banner.url" @click="gtmPromotion('header', banner)">
                            <NuxtImg :src="banner.img" :alt="banner.alt" />
                        </NuxtLink>
                    </div>
                </div>
            </template>
        </LayoutFrontendHeader>

        <!-- Main content -->
        <main id="app__main" :class="{ container: !props.fullScreen, 'px-4': props.fullScreen }">
            <!-- Toast -->
            <PrimevueToast position="top-right" group="tr" />

            <!-- Breadcrumb slot -->
            <slot name="breadcrumb" />

            <!-- Page slot -->
            <section id="app__content">
                <slot />
            </section>
        </main>

        <!-- Footer -->
        <LayoutFrontendFooter :menus="menus" :reviews="reviews" :stocklear="stocklear" />

        <!-- Bottom fixed message -->
        <div v-if="$me.isCertificateMissing()" id="app__bottom__fixed__message">
            <p>
                {{
                    $t(
                        'Your registration is not ended yet, you still have to upload your certificate.'
                    )
                }}
            </p>

            <NuxtLink :to="completionUrl">
                <PrimevueButton :label="$t('Finish my registration')" />
            </NuxtLink>
        </div>
    </NuxtErrorBoundary>
</template>
